export const baseVariables = {
	'font-family-primary': '"Inter", sans-serif',

	'spacer-xs': '4px',
	'spacer-sm': '8px',
	'spacer-md': '12px',
	'spacer-lg': '16px',
	'spacer-xl': '20px',
	'spacer-xxl': '24px',

	'radius-sm': '4px',
	'radius-md': '8px',
	'radius-lg': '12px',
	'radius-xl': '24px',
	'radius-round': '100%',

	'font-size-xs': '12px',
	'default-font-size': '14px',
	'font-size-sm': '16px',
	'font-size-md': '20px',
	'font-size-lg': '24px',
	'font-size-xl': '32px',
	'font-size-xxl': '48px',

	'line-height-sm': '1.0',
	'default-line-height': '1.2',
	'line-height-lg': '1.5',

	'default-font-weight': '400',
	'font-weight-medium': '500',
	'font-weight-semibold': '600',
	'font-weight-bold': '700'
};
