import { AppInjector } from '../../core/utils/app-injector.utils';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Directive, OnDestroy } from '@angular/core';
import { getRouteParams } from '../../core/utils/route.utils';
import { LicensePermission } from '../../_common/share/licenses/license-permission.enum';

@Directive()
export class BaseComponent implements OnDestroy {
	public subscriptions: Subscription[] = [];

	public LicensePermission = LicensePermission;

	public get params() {
		const activatedRoute = AppInjector.injector.get(ActivatedRoute);
		return getRouteParams(activatedRoute.snapshot);
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
