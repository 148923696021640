import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ForgotPasswordViewModel } from '../../../security/_common/models/forgot-password-view-model.model';
import { LoginExternalProvidersModel } from '../../../security/_common/models/login-external-providers-model.model';
import { LoginViewModel } from '../../../security/_common/models/login-view-model.model';
import { ResetPasswordViewModel } from '../../../security/_common/models/reset-password-view-model.model';
import { ValidateTokenViewModel } from '../../../core/models/validate-token-view-model.model';
import { AuthenticationType } from '../enums/authentication-type.enum';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { TokenModel } from '../../../core/models/token.model';
import { UserLockModel } from '../../../security/_common/models/user-lock.model';
import { RQHttpService } from '../../../core/services/http.service';
import { IActionResult } from '../../../server/models/iaction-result.model';

@Injectable({
	providedIn: 'root'
})
export class IDPDataService {
	constructor(private http: RQHttpService) {}

	public getAuthenticationType(options = new HttpOptionsViewModel()): Promise<AuthenticationType> {
		return this.http
			.request<AuthenticationType>('get', environment.identity + '/idp/Identity/Account/GetAuthenticationType', options)
			.toPromise();
	}

	public getExternalLogins(options = new HttpOptionsViewModel()): Promise<LoginExternalProvidersModel> {
		return this.http
			.request<LoginExternalProvidersModel>('get', environment.identity + '/idp/Identity/Account/GetExternalLogins', options)
			.toPromise();
	}

	public externalLogin(options = new HttpOptionsViewModel()): Promise<TokenModel> {
		return this.http.request<TokenModel>('get', environment.identity + '/idp/Identity/Account/ExternalLoginCallback', options).toPromise();
	}

	public login(model: LoginViewModel, options = new HttpOptionsViewModel()): Promise<TokenModel> {
		options.body = model;

		return this.http.request<TokenModel>('post', environment.identity + '/idp/Identity/Account/Login', options).toPromise();
	}

	public forgotPassword(model: ForgotPasswordViewModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = model;
		return this.http.request<IActionResult>('post', environment.identity + '/idp/Identity/Account/ForgotPassword', options).toPromise();
	}

	public sendUnlockAccountEmail(model: UserLockModel, options = new HttpOptionsViewModel()) {
		options.body = model;

		return this.http
			.request<IActionResult>('post', environment.identity + '/idp/Identity/UserEmail/SendUnlockAccountEmail', options)
			.toPromise();
	}

	public unlockAccount(model: ResetPasswordViewModel, options = new HttpOptionsViewModel()) {
		options.body = model;

		return this.http.request<IActionResult>('post', environment.identity + '/idp/Identity/Account/Unlock', options).toPromise();
	}

	public changePassword(model: ResetPasswordViewModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = model;
		return this.http.request<IActionResult>('post', environment.identity + '/idp/Identity/Account/ChangePassword', options).toPromise();
	}

	public validateUnblockToken(model: ValidateTokenViewModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = model;
		return this.http
			.request<IActionResult>('post', environment.identity + '/idp/Identity/Account/ValidateUnblockToken', options)
			.toPromise();
	}

	public validateResetPasswordToken(model: ValidateTokenViewModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = model;
		return this.http
			.request<IActionResult>('post', environment.identity + '/idp/Identity/Account/ValidateResetPasswordToken', options)
			.toPromise();
	}

	public logout(options: HttpOptionsViewModel = new HttpOptionsViewModel()): Promise<IActionResult> {
		return this.http.request<IActionResult>('post', environment.identity + '/idp/Identity/Account/InternalLogout', options).toPromise();
	}

	public getMaxPasswordAttempts(options = new HttpOptionsViewModel()): Promise<number> {
		return this.http
			.request<number>('get', environment.identity + '/idp/Infrastructure/Settings/GetMaxPasswordAttempts', options)
			.toPromise();
	}
}
