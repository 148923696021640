import * as security from '../../security/security-routes.config';
import * as infrastructure from '../../infrastructure/infrastructure-routes.config';
import * as fidelityOne from '../../financial-analysis/financial-analysis-routes.config';
import * as enterprise from '../../enterprise/enterprise-routes.config';
import * as pro from '../../control-analysis/control-analysis-routes.config';

export const RQ_ROUTES = {
	pro: { ...pro.PRO_ROUTE },
	enterprise: { ...enterprise.ENTERPRISE_ROUTE },
	security: { ...security.SECURITY_ROUTE },
	infrastructure: { ...infrastructure.INFRASTRUCTURE_ROUTE },
	fidelityOne: { ...fidelityOne.FIDELITY_ONE_ROUTE }
};
