export function isNullOrUndefined(value: unknown): boolean {
	return value === null || value === undefined;
}

export function isNumber(value: unknown): boolean {
	return typeof value === 'number';
}

export function isEmptyString(value: unknown) {
	return typeof value === 'string' && value.length === 0;
}

export function clone<T>(value: T) {
	return isNullOrUndefined(value) ? value : (JSON.parse(JSON.stringify(value)) as T);
}

export function generateUUID() {
	return window.crypto.randomUUID();
}
