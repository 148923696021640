import { RoleModel } from '../../../server/models/role-model.model';

export class UserRoleViewModel extends RoleModel {
	public value!: boolean;
}

export class UserEditViewModel {
	public id?: string;

	public 'roles'!: UserRoleViewModel[];

	public 'name'!: string;

	public 'title'!: string;

	public 'email'!: string;

	public 'providerUserId'!: string;

	public hasDefaultRoleForSSOLogin!: boolean;
}

export class UserViewModel {
	public id!: string;

	public 'name'!: string;

	public 'email'!: string;

	public 'providerUserId'!: string;

	public 'roles'!: UserRoleViewModel[];
}

export class UserEditConfigViewModel {
	public isExportRoleVisible!: boolean;
}

export class RoleAccessRightsViewModel {
	[key: string]: Array<string>;
}
