import { Injectable } from '@angular/core';
import { EFeatureFlag } from '../../../server/enums/efeature-flag.enum';
import { FeatureFlag } from '../../../server/models/feature-flag.model';
import { FeatureFlagDataService } from '../../../server/services/infrastructure/feature-flag-data.service';

@Injectable({
	providedIn: 'root'
})
export class FeatureFlagService {
	private allFeatureFlags!: Array<FeatureFlag>;

	constructor(private featureFlagDataService: FeatureFlagDataService) {}

	public async load() {
		this.allFeatureFlags = await this.featureFlagDataService.getAll();
	}

	public getAllFeatureFlags() {
		return this.allFeatureFlags;
	}

	public isEnabled(feature: EFeatureFlag | string) {
		let hasFeature = false;

		if (this.allFeatureFlags && feature) {
			const selectedFeature = this.allFeatureFlags.find(x => x.feature === feature);

			hasFeature = selectedFeature?.turnedOn ?? false;
		}

		return hasFeature;
	}
}
